











import Vue from 'vue';
import { timezones } from './specifications';
export default Vue.extend({
  data() {
    return {
      select: '',
      timezones,
    };
  },
  props: ['timezone'],
  watch: {
    select() {
      this.$emit('update:timezone', this.select);
    },
    timezone(newTimezone: string) {
      this.setTimezone(newTimezone);
    },
  },
  methods: {
    setTimezone(timezone: string) {
      this.select = timezone;
    },
  },
});
