export const timezones = [
  { name: 'Калининград: +02 GMT', value: '+02:00' },
  { name: 'Москва: +03 GMT', value: '+03:00' },
  { name: 'Самара: +04 GMT', value: '+04:00' },
  { name: 'Екатеринбург: +05 GMT', value: '+05:00' },
  { name: 'Омск: +06 GMT', value: '+06:00' },
  { name: 'Красноярск: +07 GMT', value: '+07:00' },
  { name: 'Иркутск: +08 GMT', value: '+08:00' },
  { name: 'Якутск: +09 GMT', value: '+09:00' },
  { name: 'Владивосток: +10 GMT', value: '+10:00' },
  { name: 'Магадан: +11 GMT', value: '+11:00' },
  { name: 'Камчатка: +12 GMT', value: '+12:00' },
];
