import { format } from 'date-fns';

export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

export const dateToISOFormat = (datetime: Date | null, timezone: string = ''): string => {
  const dt: Date = datetime || new Date();
  if (timezone) {
    return format(dt, `yyyy-MM-dd'T'kk:mm:ss.SS${timezone}`);
  }
  return format(dt, `yyyy-MM-dd'T'kk:mm:ss`);
};
