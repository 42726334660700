












import { ValidationProvider } from 'vee-validate';
import { Vue, Component, Mixins, Emit, Watch } from 'vue-property-decorator';

// We declare the props separately
// to make props types inferable.
const AppProps = Vue.extend({
  props: {
    rules: {
      type: [String, Object],
      default: '',
    },
    value: {
      default: null,
    },
  },
});

@Component({
  components: {
    ValidationProvider,
  },
})
export default class VSelectWithValidation extends AppProps {
  private innerValue = this.value || '';

  @Emit('input')
  public changeInputValue(val) {
    return val;
  }

  @Watch('innerValue')
  public onInnerValueChange(val) {
    this.changeInputValue(val);
  }

  @Watch('value')
  public onValueChange(val) {
    this.innerValue = val;
  }

}
